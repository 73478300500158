import { FC, useState } from 'react';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import {
  RiskClass,
  RiskClassCategory,
  RiskClassInput,
  RiskClassRole,
  RiskClassSpecialCase,
} from '../__generated__/gql/graphql';
import { risk, role, specialCase } from './RiskClassificationQuestions';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '@/components/ui/spinner';
import { formatEnumValue } from '@/utils/formatEnumUtils';

interface QuestionaireResultProps {
  projectId?: string;
  setRiskClass?: (riskClass: RiskClass) => void;
  nextStepRiskClass?: () => void;
  language?: 'EN' | 'DE';
}

const UPDATE_PROJECT_MUTATION = gql`
  mutation UpdateRiskClassInProject(
    $riskClass: RiskClassInput
    $projectId: String!
  ) {
    updateProject(projectId: $projectId, riskClass: $riskClass) {
      __typename
      project {
        __typename
        riskClass {
          __typename
          riskClassRole
          riskClassCategory
          riskClassSpecialCase
        }
        id
      }
    }
  }
`;

export const getRiskClassCategoryText = (
  category: RiskClassCategory | undefined | null | string
) => {
  if (!category) return 'Not Defined';
  return formatEnumValue(category);
};

export const getRiskClassRoleText = (
  role: RiskClassRole | undefined | null
) => {
  if (!role) return '';
  return formatEnumValue(role);
};

export const getRiskClassSpecialCaseText = (
  specialCase: RiskClassSpecialCase | undefined | null
) => {
  if (!specialCase) return 'GPAI Status Undefined';

  switch (specialCase) {
    case RiskClassSpecialCase.Gpai:
      return 'GPAI System';
    case RiskClassSpecialCase.SystemicRiskGpai:
      return 'GPAI System with Systemic Risk';
    case RiskClassSpecialCase.FreeAndOpenSourceGpai:
      return 'Free & Open-Source GPAI System';
    case RiskClassSpecialCase.NonGpai:
      return 'Non GPAI System';
    default:
      return formatEnumValue(specialCase);
  }
};

const QuestionaireResult: FC<QuestionaireResultProps> = ({
  projectId,
  setRiskClass,
  nextStepRiskClass,
  language,
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const riskClass: RiskClassInput = {
    riskClassCategory: risk,
    riskClassRole: role,
    riskClassSpecialCase: specialCase,
  };

  const [updateProject] = useMutation(UPDATE_PROJECT_MUTATION, {
    variables: {
      projectId,
      riskClass,
    },
  });

  const submitUpdateRiskClass = async () => {
    setIsLoading(true);
    try {
      await updateProject();
      navigate('/projects');
      toast.success('Successfully saved Risk Class');
    } catch {
      toast.error('Saving Risk Class failed');
    } finally {
      setIsLoading(false);
    }
  };

  const submitCreateRiskClass = () => {
    setIsLoading(true);
    try {
      if (setRiskClass) {
        setRiskClass(riskClass as RiskClass);
      }
      if (nextStepRiskClass) {
        nextStepRiskClass();
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="w-4/5 p-8 flex flex-col items-center justify-center gap-2 border-2">
      <p className="text-2xl">Role: {getRiskClassRoleText(role)}</p>
      {role !== 'OUT_OF_SCOPE' && risk && (
        <p className="text-2xl">Risk: {getRiskClassCategoryText(risk)}</p>
      )}
      {specialCase && (
        <p className="text-2xl">{getRiskClassSpecialCaseText(specialCase)}</p>
      )}

      {setRiskClass ? (
        <Button
          className="mt-10"
          variant="default"
          onClick={submitCreateRiskClass}
          disabled={isLoading}
        >
          {isLoading ? <Spinner size="small" className="mr-2" /> : null}
          {language === 'DE' ? 'Speichern' : 'Save'}
        </Button>
      ) : (
        <Button
          className="mt-10"
          variant="default"
          onClick={submitUpdateRiskClass}
          disabled={isLoading}
        >
          {isLoading ? (
            <Spinner size="small" className="mr-2 text-black" />
          ) : null}
          {language === 'DE' ? 'Speichern' : 'Save'}
        </Button>
      )}
    </Card>
  );
};

export default QuestionaireResult;
