import { Button } from '@/components/ui/button';
import { FC } from 'react';

interface QuestionnaireAnswerButtonProps {
  questionId: number;
  option: { name: string; value: number };
  handleNextStep: (id: number, value: number[]) => void;
}

const QuestionnaireAnswerButton: FC<QuestionnaireAnswerButtonProps> = ({
  questionId,
  option,
  handleNextStep,
}) => {
  return (
    <Button
      variant="outline"
      className="w-full h-[120px] px-5 py-5 flex items-center justify-center bg-dark-blue-400 border-gray-400 hover:bg-dark-blue-500 cursor-pointer"
      onClick={() => handleNextStep(questionId, [option.value])}
    >
      <span className="w-full h-full flex items-center justify-center text-white no-underline normal-case text-sm whitespace-pre-line break-words">
        {option.name}
      </span>
    </Button>
  );
};

export default QuestionnaireAnswerButton;
