import { FC, useState } from 'react';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { RiskClass, RiskClassInput } from '../__generated__/gql/graphql';
import { role } from './ValidationQuestions';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '@/components/ui/spinner';

interface QuestionaireInterimResultProps {
  projectId?: string;
  setRiskClass?: (riskClass: RiskClass) => void;
  nextStepRiskClass?: () => void;
  onNextValidationStage?: () => void;
  language?: 'EN' | 'DE';
}

const UPDATE_PROJECT_MUTATION = gql`
  mutation UpdateRiskClassInterimStep(
    $riskClass: RiskClassInput
    $projectId: String!
  ) {
    updateProject(projectId: $projectId, riskClass: $riskClass) {
      __typename
      project {
        __typename
        riskClass {
          __typename
          riskClassRole
          riskClassCategory
        }
        id
      }
    }
  }
`;

const QuestionaireInterimResult: FC<QuestionaireInterimResultProps> = ({
  projectId,
  setRiskClass,
  nextStepRiskClass,
  onNextValidationStage,
  language = 'EN',
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const riskClass: RiskClassInput = {
    riskClassRole: role,
  };

  const [updateProject] = useMutation(UPDATE_PROJECT_MUTATION, {
    variables: {
      projectId,
      riskClass,
    },
  });

  const submitUpdateRiskClass = async () => {
    setIsLoading(true);
    try {
      await updateProject();
      navigate('/projects');
      toast.success(
        language === 'DE'
          ? 'Risikoklasse erfolgreich gespeichert'
          : 'Successfully saved Risk Class'
      );
    } catch {
      toast.error(
        language === 'DE'
          ? 'Speichern der Risikoklasse fehlgeschlagen'
          : 'Saving Risk Class failed'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const submitCreateRiskClass = () => {
    setIsLoading(true);
    if (setRiskClass) {
      setRiskClass(riskClass as RiskClass);
    }
    if (nextStepRiskClass) {
      nextStepRiskClass();
    }
    setIsLoading(false);
  };

  return (
    <Card className="w-4/5 p-8 flex flex-col border-2 items-center justify-center gap-2">
      {role ? (
        setRiskClass ? (
          <>
            <h5 className="text-xl font-semibold">
              {language === 'DE'
                ? 'Das betrachtete System ist wahrscheinlich kein KI-System gemäß dem EU-KI-Gesetz'
                : 'System under consideration is likely no AI system under the EU AI Act'}
            </h5>
            <Button
              className="mt-10"
              variant="default"
              onClick={submitCreateRiskClass}
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner className="text-black" />
              ) : language === 'DE' ? (
                'Speichern'
              ) : (
                'Save'
              )}
            </Button>
          </>
        ) : (
          <>
            <h5 className="text-xl font-semibold">
              {language === 'DE'
                ? 'Das betrachtete System ist wahrscheinlich kein KI-System gemäß dem EU AI Act'
                : 'System under consideration is likely no AI system under the EU AI Act'}
            </h5>
            <Button
              className="mt-10"
              variant="default"
              onClick={submitUpdateRiskClass}
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner className="text-black" />
              ) : language === 'DE' ? (
                'Weiter'
              ) : (
                'Next'
              )}
            </Button>
          </>
        )
      ) : (
        <>
          <h5 className="text-xl font-semibold">
            {language === 'DE'
              ? 'Das System wird wahrscheinlich als KI-System gemäß dem EU AI Act angesehen.'
              : 'The system is likely to be considered as an AI system under the EU AI Act.'}
          </h5>
          <Button
            className="mt-10"
            variant="default"
            onClick={onNextValidationStage}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner className="text-black" />
            ) : language === 'DE' ? (
              'Risikoklassifizierung starten'
            ) : (
              'Start Risk Classification'
            )}
          </Button>
        </>
      )}
    </Card>
  );
};

export default QuestionaireInterimResult;
