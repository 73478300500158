import { FC } from 'react';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';

interface StartScreenProps {
  onNextStartStage: () => void;
  language?: 'EN' | 'DE';
}

const StartScreen: FC<StartScreenProps> = ({
  onNextStartStage,
  language = 'EN',
}) => {
  return (
    <Card
      className="w-4/5 h-4/5 bg-dark-blue-300 flex flex-col
        justify-center items-center gap-[60px] p-8 shadow-lg
        transition-all duration-300 ease-in-out hover:translate-y-[-5px]
        hover:shadow-xl"
    >
      <h2 className="text-3xl font-bold text-[#FBBF24]">
        {language === 'DE'
          ? 'Welche Auswirkungen hat die KI Verordnung auf uns?'
          : 'How am I affected by the EU AI Act?'}
      </h2>
      <p className="text-base whitespace-pre-wrap leading-normal">
        {language === 'DE'
          ? 'Dieses Questionnaire geht davon aus, dass:\n\n• Sie das betrachtete KI-System nicht ausschließlich für den persönlichen Gebrauch einsetzen (d.h. Sie verwenden es im beruflichen Kontext)\n• Ihre Organisation in der EU ihren Geschäftssitz hat oder hier mit einer Niederlassung ansässig ist\n• Die Ausgabe des betrachteten KI-Systems (von Empfängern) in der EU verwendet wird'
          : 'This assessment assumes that:\n\n• You are not deploying the considered AI system for personal use only (i.e., you are using it in a professional context)\n• You are located or established in the EU\n• The output of the considered AI system will be used (by recipients) in the EU'}
      </p>
      <Button
        variant="default"
        onClick={onNextStartStage}
        className="text-base font-medium"
      >
        {language === 'DE' ? 'Questionnaire starten' : 'Start Questionnaire'}
      </Button>
    </Card>
  );
};

export default StartScreen;
