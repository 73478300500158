import { DateTime } from 'luxon';
import { FC, Suspense, useMemo, useCallback, useEffect, useRef } from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { IntervalData } from '../../utils/generateIntervals';
import {
  Maybe,
  TrainingCompletion,
  User,
} from '../../__generated__/gql/graphql';
import UserTag from '../../components/UserTag';
import {
  Table,
  TableHeader,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '../../components/ui/table';
import { Badge } from '../../components/ui/badge';
import { RefreshCw, AlertTriangle } from 'lucide-react';
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
  PaginationEllipsis,
} from '@/components/ui/pagination';
import { Skeleton } from '@/components/ui/skeleton';
import { FilterOptions, usePagination } from '../../hooks/usePagination';
import { useDebounce } from '../../hooks/useDebounce';

export const GET_USERS_FOR_TRAINING_INTERVAL = gql`
  query GetUsersForTrainingInterval(
    $includeAuditors: Boolean!
    $search: String
    $status: String
    $intervalStart: String!
    $intervalEnd: String!
    $first: Int!
    $after: String
    $trainingId: String!
  ) {
    usersForTrainingInterval(
      includeAuditors: $includeAuditors
      search: $search
      status: $status
      intervalStart: $intervalStart
      intervalEnd: $intervalEnd
      first: $first
      after: $after
      trainingId: $trainingId
    ) {
      items {
        user {
          id
          name
          email
        }
        status
        completions {
          id
          completionDate
        }
      }
      hasNextPage
      hasPreviousPage
      totalCount
      totalUsersWithoutFilter
    }
  }
`;

interface CompletionTableProps {
  intervalObj: IntervalData;
  completionLogs: Maybe<Maybe<TrainingCompletion>[]> | undefined;
  searchTerm: string;
  statusFilter: string;
  onTotalUsersChange?: (count: number) => void;
  onValidUserIdsChange?: (userIds: Set<string>) => void;
}

interface UserWithStatus {
  user: User;
  status: string;
  completions: {
    id: string;
    completionDate: string;
  }[];
}

interface UsersQueryData {
  usersForTrainingInterval: {
    items: UserWithStatus[];
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    totalCount: number;
    totalUsersWithoutFilter: number;
  };
}

interface UsersQueryVariables {
  includeAuditors: boolean;
  search?: string | null;
  status?: string | null;
  intervalStart: string;
  intervalEnd: string;
  first: number;
  after?: string | null;
  trainingId: string;
}

const CompletionTableSkeleton: FC<{ rowCount: number }> = ({ rowCount }) => (
  <div>
    <Table className="min-w-full text-sm">
      <TableHeader>
        <TableRow>
          <TableHead className="py-2 px-4 text-left">
            <Skeleton className="h-5 w-24" />
          </TableHead>
          <TableHead className="py-2 px-4 text-left">
            <Skeleton className="h-5 w-32" />
          </TableHead>
          <TableHead className="py-2 px-4 text-left">
            <Skeleton className="h-5 w-20" />
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {Array.from({ length: rowCount }).map((_, idx) => (
          <TableRow key={`skeleton-${idx}`}>
            <TableCell className="py-2 px-4">
              <Skeleton className="h-5 w-40" />
            </TableCell>
            <TableCell className="py-2 px-4">
              <Skeleton className="h-5 w-24" />
            </TableCell>
            <TableCell className="py-2 px-4">
              <Skeleton className="h-5 w-20" />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <div className="h-14"></div>
  </div>
);

const UserStatusRow: FC<{
  userWithStatus: UserWithStatus;
  isPastInterval: boolean;
}> = ({ userWithStatus, isPastInterval }) => {
  const { user, status, completions } = userWithStatus;

  const statusColor = useMemo(() => {
    switch (status) {
      case 'Completed':
        return 'text-green-600';
      case 'Overdue':
        return 'text-red-500';
      default:
        return 'text-primary';
    }
  }, [status]);

  if (status === 'Completed' && completions.length > 0) {
    const sortedCompletions = [...completions].sort(
      (a, b) =>
        DateTime.fromISO(b.completionDate).toMillis() -
        DateTime.fromISO(a.completionDate).toMillis()
    );

    const latestCompletion = sortedCompletions[0];
    const formattedDate = latestCompletion?.completionDate
      ? DateTime.fromISO(latestCompletion.completionDate).toLocaleString(
          DateTime.DATE_MED
        )
      : '-';

    const hasMultipleCompletions = completions.length > 1;

    return (
      <TableRow key={userWithStatus.user.id}>
        <TableCell className="py-2 px-4">
          <UserTag user={userWithStatus.user} variant="text" size="small" />
        </TableCell>
        <TableCell className="py-2 px-4">
          {formattedDate}
          {hasMultipleCompletions && (
            <Badge variant="outline" className="ml-2 text-xs">
              <RefreshCw className="mr-1 h-3 w-3 inline" aria-hidden="true" />
              <span>{completions.length}x</span>
            </Badge>
          )}
        </TableCell>
        <TableCell className={`py-2 px-4 ${statusColor}`}>
          {userWithStatus.status}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow key={user.id}>
      <TableCell className="py-2 px-4">
        <div className={isPastInterval ? 'opacity-60' : ''}>
          <UserTag user={user} variant="text" size="small" />
        </div>
      </TableCell>
      <TableCell className="py-2 px-4">-</TableCell>
      <TableCell className={`py-2 px-4 ${statusColor}`}>
        {status === 'Overdue' && (
          <AlertTriangle className="inline mr-1 h-3 w-3" aria-hidden="true" />
        )}
        {status}
      </TableCell>
    </TableRow>
  );
};

const CompletionTableContent: FC<CompletionTableProps> = ({
  intervalObj,
  searchTerm,
  statusFilter,
  onTotalUsersChange,
  onValidUserIdsChange,
}) => {
  const itemsPerPage = 10;
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const hasReportedTotal = useRef(false);
  const client = useApolloClient();

  const getVariables = useCallback(
    (
      _page: number,
      itemsPerPage: number,
      filters?: FilterOptions,
      cursor?: string | null
    ): UsersQueryVariables => {
      return {
        includeAuditors: false,
        search: filters?.searchTerm ? String(filters.searchTerm) : undefined,
        status:
          filters?.statusFilter !== 'All' && filters?.statusFilter
            ? String(filters.statusFilter)
            : undefined,
        intervalStart: intervalObj.start.toISO() || '',
        intervalEnd: intervalObj.end.toISO() || '',
        first: itemsPerPage,
        after: cursor,
        trainingId: intervalObj.trainingId || '',
      };
    },
    [intervalObj]
  );

  const getTotalItems = useCallback((data: UsersQueryData) => {
    return data.usersForTrainingInterval.totalCount;
  }, []);

  const getHasNextPage = useCallback((data: UsersQueryData) => {
    return data.usersForTrainingInterval.hasNextPage;
  }, []);

  const getCursor = useCallback((data: UsersQueryData) => {
    const items = data.usersForTrainingInterval.items;
    if (items.length > 0) {
      return items[items.length - 1].user.id;
    }
    return null;
  }, []);

  const getResponseItems = useCallback((data: UsersQueryData) => {
    return data.usersForTrainingInterval.items;
  }, []);

  const {
    data,
    loading,
    currentPage,
    totalPages,
    hasNextPage,
    hasPreviousPage,
    handleNextPage,
    handlePreviousPage,
    goToPage,
    applyFilters,
    isPageAccessible,
  } = usePagination<UsersQueryData, UsersQueryVariables>({
    query: GET_USERS_FOR_TRAINING_INTERVAL,
    itemsPerPage,
    getVariables,
    getTotalItems,
    getHasNextPage,
    getCursor,
    getResponseItems,
    queryOptions: {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  });

  useEffect(() => {
    if (data?.usersForTrainingInterval?.items && onValidUserIdsChange) {
      const completedUserIds = new Set<string>();

      data.usersForTrainingInterval.items.forEach(item => {
        if (item.status === 'Completed') {
          completedUserIds.add(item.user.id);
        }
      });

      if (hasNextPage && statusFilter !== 'Completed') {
        const fetchCompletedUsers = async () => {
          try {
            const response = await client.query({
              query: GET_USERS_FOR_TRAINING_INTERVAL,
              variables: {
                includeAuditors: false,
                status: 'Completed',
                intervalStart: intervalObj.start.toISO() || '',
                intervalEnd: intervalObj.end.toISO() || '',
                first: 1000,
                trainingId: intervalObj.trainingId || '',
              },
              fetchPolicy: 'network-only',
            });

            if (response.data?.usersForTrainingInterval?.items) {
              response.data.usersForTrainingInterval.items.forEach(
                (item: UserWithStatus) => {
                  completedUserIds.add(item.user.id);
                }
              );
            }

            onValidUserIdsChange(completedUserIds);
          } catch (error) {
            console.error('Error fetching completed users:', error);
            onValidUserIdsChange(completedUserIds);
          }
        };

        fetchCompletedUsers();
      } else {
        onValidUserIdsChange(completedUserIds);
      }
    }
  }, [
    data,
    hasNextPage,
    onValidUserIdsChange,
    client,
    intervalObj,
    statusFilter,
  ]);

  useEffect(() => {
    applyFilters({
      searchTerm: debouncedSearchTerm,
      statusFilter,
    });
  }, [debouncedSearchTerm, statusFilter, applyFilters]);

  useEffect(() => {
    if (
      !hasReportedTotal.current &&
      data?.usersForTrainingInterval?.totalUsersWithoutFilter !== undefined &&
      data.usersForTrainingInterval.totalUsersWithoutFilter > 0 &&
      onTotalUsersChange
    ) {
      const totalUsers = data.usersForTrainingInterval.totalUsersWithoutFilter;
      onTotalUsersChange(totalUsers);
      hasReportedTotal.current = true;
    }
  }, [data, onTotalUsersChange]);

  const calculatePageNumbers = useCallback(() => {
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    const halfVisible = Math.floor(maxVisiblePages / 2);
    let startPage = Math.max(1, currentPage - halfVisible);
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
  }, [totalPages, currentPage]);

  if (loading && !data) {
    return <CompletionTableSkeleton rowCount={itemsPerPage} />;
  }

  const users = data?.usersForTrainingInterval?.items || [];
  const pageNumbers = calculatePageNumbers();
  const now = DateTime.now();
  const isPastInterval = intervalObj.end < now;

  return (
    <div>
      <Table className="min-w-full text-sm">
        <TableHeader>
          <TableRow>
            <TableHead className="py-2 px-4 text-left">User</TableHead>
            <TableHead className="py-2 px-4 text-left">
              Completion Date
            </TableHead>
            <TableHead className="py-2 px-4 text-left">Status</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {users.length === 0 ? (
            <TableRow>
              <TableCell colSpan={3} className="py-8 text-center text-gray-500">
                No users found matching the current filters.
              </TableCell>
            </TableRow>
          ) : (
            users.map(userWithStatus => (
              <UserStatusRow
                key={userWithStatus.user.id}
                userWithStatus={userWithStatus}
                isPastInterval={isPastInterval}
              />
            ))
          )}
        </TableBody>
      </Table>

      {/* Pagination UI */}
      {totalPages > 1 && (
        <div className="flex items-center justify-center my-4">
          <Pagination aria-label="User results pagination">
            <PaginationContent>
              <PaginationItem>
                <PaginationPrevious
                  onClick={handlePreviousPage}
                  className={
                    !hasPreviousPage
                      ? 'pointer-events-none opacity-50'
                      : 'cursor-pointer'
                  }
                  aria-disabled={!hasPreviousPage}
                />
              </PaginationItem>

              {pageNumbers[0] > 1 && (
                <>
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => goToPage(1)}
                      aria-label="Go to first page"
                    >
                      1
                    </PaginationLink>
                  </PaginationItem>
                  {pageNumbers[0] > 2 && (
                    <PaginationItem>
                      <PaginationEllipsis />
                    </PaginationItem>
                  )}
                </>
              )}

              {pageNumbers.map(pageNum => (
                <PaginationItem key={pageNum}>
                  <PaginationLink
                    isActive={pageNum === currentPage}
                    onClick={() =>
                      isPageAccessible(pageNum) && goToPage(pageNum)
                    }
                    aria-current={pageNum === currentPage ? 'page' : undefined}
                    className={
                      !isPageAccessible(pageNum) && pageNum !== currentPage
                        ? 'pointer-events-none opacity-50'
                        : 'cursor-pointer'
                    }
                    aria-disabled={!isPageAccessible(pageNum)}
                  >
                    {pageNum}
                  </PaginationLink>
                </PaginationItem>
              ))}

              {pageNumbers[pageNumbers.length - 1] < totalPages && (
                <>
                  {pageNumbers[pageNumbers.length - 1] < totalPages - 1 && (
                    <PaginationItem>
                      <PaginationEllipsis />
                    </PaginationItem>
                  )}
                  <PaginationItem>
                    <PaginationLink
                      onClick={() =>
                        isPageAccessible(totalPages) && goToPage(totalPages)
                      }
                      aria-label={`Go to last page, page ${totalPages}`}
                      className={
                        !isPageAccessible(totalPages)
                          ? 'pointer-events-none opacity-50'
                          : 'cursor-pointer'
                      }
                      aria-disabled={!isPageAccessible(totalPages)}
                    >
                      {totalPages}
                    </PaginationLink>
                  </PaginationItem>
                </>
              )}

              <PaginationItem>
                <PaginationNext
                  onClick={handleNextPage}
                  className={
                    !hasNextPage
                      ? 'pointer-events-none opacity-50'
                      : 'cursor-pointer'
                  }
                  aria-disabled={!hasNextPage}
                />
              </PaginationItem>
            </PaginationContent>
          </Pagination>
        </div>
      )}
    </div>
  );
};

const CompletionTable: FC<CompletionTableProps> = props => {
  return (
    <Suspense fallback={<CompletionTableSkeleton rowCount={10} />}>
      <CompletionTableContent {...props} />
    </Suspense>
  );
};

export default CompletionTable;
