import { FC, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import {
  QuestionnaireQuestion,
  germanRiskQuestionnaireCategories,
  englishRiskQuestionnaireCategories,
} from './RiskClassificationQuestions';
import QuestionnaireAnswerButton from './QuestionnaireAnswersButton';
import QuestionnaireAnswerCheckbox from './QuestionnaireAnswersCheckbox';
import { Answer, StageTypes } from './RiskClassification';
import QuestionnaireResult from './QuestionnaireResult';
import HorizontalLinearStepper from '../sign_up/HorizontalLinearStepper';
import { RiskClass } from '../__generated__/gql/graphql';
import QuestionnaireInterimResult from './QuestionnaireInterimResult';

interface ClassificationQuestionnaireProps {
  projectId?: string;
  questionaireQuestion: QuestionnaireQuestion;
  handleNextStep: (id: number, values: number[]) => void;
  handleBackStep: () => void;
  answers: Answer[];
  questionPosition: number;
  setRiskClass?: (riskClass: RiskClass) => void;
  nextStepRiskClass?: () => void;
  stage?: StageTypes;
  onNextValidationStage?: () => void;
  language?: 'EN' | 'DE';
}

const ClassificationQuestionnaire: FC<ClassificationQuestionnaireProps> = ({
  projectId,
  questionaireQuestion,
  handleNextStep,
  handleBackStep,
  answers,
  questionPosition,
  setRiskClass,
  nextStepRiskClass,
  stage,
  onNextValidationStage,
  language,
}) => {
  const [checkboxValues, setCheckboxValues] = useState<number[]>(
    answers[questionPosition]?.values ?? []
  );

  const handleCheckboxValue = (value: number) => {
    setCheckboxValues(prevValues => {
      if (value === questionaireQuestion.options.length - 1) {
        // if last checkbox checked deselect all others
        return prevValues.includes(value) ? [] : [value];
      } else {
        const newValues = prevValues.includes(value)
          ? prevValues.filter(v => v !== value)
          : [...prevValues, value];
        // deselect last checkbox if any other is selected
        return newValues.filter(
          v => v !== questionaireQuestion.options.length - 1
        );
      }
    });
  };

  const isChecked = (index: number) => {
    return checkboxValues.includes(index);
  };

  return (
    <Card className="bg-dark-blue-300 w-[85%] h-[80%] ] p-5 pt-10 flex justify-between overflow-auto">
      <div className="flex h-full items-center">
        {questionPosition > 0 ? (
          <Button
            variant="default"
            size="icon"
            onClick={handleBackStep}
            className="w-10 h-10"
          >
            <ChevronLeft className="h-4 w-4" />
          </Button>
        ) : (
          <div className="w-10" />
        )}
      </div>

      <div className="flex flex-col items-center flex-grow gap-5 max-w-[80%]">
        {questionaireQuestion.category !== 5 ? (
          <HorizontalLinearStepper
            step={questionaireQuestion.category}
            stepLabels={
              language === 'EN'
                ? englishRiskQuestionnaireCategories
                : germanRiskQuestionnaireCategories
            }
          />
        ) : null}
        <h2>{questionaireQuestion.title}</h2>
        <p className="text-lg whitespace-pre-line">
          {questionaireQuestion.description}
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-4 w-full">
          {questionaireQuestion.options.map((option, index) => (
            <div key={index} className="w-full">
              {questionaireQuestion.type === 'MULTIPLE_CHOICE' ? (
                <QuestionnaireAnswerCheckbox
                  key={option.name}
                  option={option}
                  handleCheckboxValue={handleCheckboxValue}
                  isChecked={isChecked(index)}
                />
              ) : questionaireQuestion.type === 'SINGLE_CHOICE' ? (
                <QuestionnaireAnswerButton
                  option={option}
                  handleNextStep={handleNextStep}
                  questionId={questionaireQuestion.id}
                />
              ) : null}
            </div>
          ))}
        </div>

        {questionPosition === 37 &&
        stage === StageTypes.RiskClassQuestionnaireStage ? (
          <QuestionnaireResult
            projectId={projectId}
            setRiskClass={setRiskClass}
            nextStepRiskClass={nextStepRiskClass}
            language={language}
          />
        ) : null}

        {questionPosition === 6 &&
        stage === StageTypes.ValidationQuestionnaireStage ? (
          <QuestionnaireInterimResult
            projectId={projectId}
            setRiskClass={setRiskClass}
            nextStepRiskClass={nextStepRiskClass}
            onNextValidationStage={onNextValidationStage}
            language={language}
          />
        ) : null}
      </div>

      <div className="flex h-full items-center">
        {questionaireQuestion.type === 'MULTIPLE_CHOICE' ? (
          <Button
            variant="default"
            size="icon"
            disabled={checkboxValues.length === 0}
            onClick={() =>
              handleNextStep(questionaireQuestion.id, checkboxValues)
            }
            className={cn(
              'w-10 h-10',
              checkboxValues.length === 0 && 'bg-slate-800'
            )}
          >
            <ChevronRight className="h-4 w-4" />
          </Button>
        ) : (
          <div className="w-10" />
        )}
      </div>
    </Card>
  );
};

export default ClassificationQuestionnaire;
