import { useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import ClassificationQuestionnaire from './ClassificationQuestionnaire';
import StartScreen from './StartScreen';
import {
  englishRiskClassificationQuestionnaireQuestions,
  germanRiskClassificationQuestionnaireQuestions,
  riskClassificationQuestionActions,
  risk,
  clearRisk,
  specialCase,
  possibleSpecialCaseOnQuestion,
  clearSpecialCase,
  riskClassHistory,
} from './RiskClassificationQuestions';
import {
  englishValidationQuestionnaireQuestions,
  germanValidationQuestionnaireQuestions,
  validationQuestionActions,
} from './ValidationQuestions';
import { RiskClass } from '../__generated__/gql/graphql';
import { Button } from '@/components/ui/button';

export interface Answer {
  id: number;
  values: number[];
}

type QuestionnaireState = {
  questionPosition: number;
  answers: Answer[];
  history: number[];
};

export enum StageTypes {
  StartStage,
  ValidationQuestionnaireStage,
  RiskClassQuestionnaireStage,
}

type QuestionnaireAction =
  | { type: 'NEXT_STEP'; id: number; values: number[] }
  | { type: 'BACK_STEP' }
  | { type: 'RESET_STATE' };

const initialQuestionnaireState: QuestionnaireState = {
  questionPosition: 0,
  answers: [],
  history: [],
};

const reducer = (
  questionnaireState: QuestionnaireState,
  action: QuestionnaireAction,
  actionMap: { [key: number]: (answers: Answer[]) => number }
): QuestionnaireState => {
  switch (action.type) {
    case 'RESET_STATE':
      return initialQuestionnaireState;
    case 'NEXT_STEP': {
      const { id, values } = action;
      const answers = [...questionnaireState.answers];
      if (answers[id]) {
        answers[id] = { ...answers[id], values };
      } else {
        answers[id] = { id, values };
      }

      const nextPosition = actionMap[id]
        ? actionMap[id](answers)
        : questionnaireState.questionPosition + 1;

      return {
        ...questionnaireState,
        questionPosition: nextPosition,
        answers,
        history: [
          ...questionnaireState.history,
          questionnaireState.questionPosition,
        ],
      };
    }
    case 'BACK_STEP': {
      const previousSelectedRisk =
        riskClassHistory[riskClassHistory.length - 1];
      if (
        previousSelectedRisk &&
        previousSelectedRisk[1] === risk &&
        previousSelectedRisk[0] ===
          questionnaireState.history[questionnaireState.history.length - 1]
      ) {
        if (riskClassHistory[riskClassHistory.length - 2]) {
          clearRisk(riskClassHistory[riskClassHistory.length - 2][1]);
        } else clearRisk();
        riskClassHistory.pop();
      }
      const possibleSpecialCases =
        possibleSpecialCaseOnQuestion[
          questionnaireState.history[questionnaireState.history.length - 1]
        ] || [];
      possibleSpecialCases.forEach(possibleSpecialCase => {
        if (possibleSpecialCase === specialCase) {
          clearSpecialCase();
        }
      });
      const previousPosition =
        questionnaireState.history[questionnaireState.history.length - 1] || 0;
      const history = questionnaireState.history.slice(0, -1);
      const answers = [...questionnaireState.answers];
      if (previousPosition >= 0) {
        delete answers[previousPosition];
      }

      return {
        ...questionnaireState,
        questionPosition: previousPosition,
        answers,
        history,
      };
    }
    default:
      return questionnaireState;
  }
};

interface RiskClassificationProps {
  setRiskClass?: (riskClass: RiskClass | undefined) => void;
  nextStepRiskClass?: () => void;
}

export default function RiskClassification({
  setRiskClass,
  nextStepRiskClass,
}: RiskClassificationProps) {
  const { projectId } = useParams<{
    projectId: string;
  }>();

  const [stage, setStage] = useState<StageTypes>(StageTypes.StartStage);

  const [language, setLanguage] = useState<'EN' | 'DE'>('EN');

  const toggleLanguage = () => {
    setLanguage(prevLanguage => (prevLanguage === 'EN' ? 'DE' : 'EN'));
  };

  const [questionnaireState, dispatch] = useReducer(
    (state: QuestionnaireState, action: QuestionnaireAction) => {
      const actionMap =
        stage === StageTypes.ValidationQuestionnaireStage
          ? validationQuestionActions
          : riskClassificationQuestionActions;
      return reducer(state, action, actionMap);
    },
    initialQuestionnaireState
  );

  const handleBackStep = () => {
    dispatch({ type: 'BACK_STEP' });
  };

  const handleNextStep = (id: number, values: number[]) => {
    dispatch({ type: 'NEXT_STEP', id, values });
  };

  function onNextStartStage() {
    setStage(StageTypes.ValidationQuestionnaireStage);
  }

  function onNextValidationStage() {
    setStage(StageTypes.RiskClassQuestionnaireStage);
    dispatch({ type: 'RESET_STATE' });
  }

  const stages: Record<StageTypes, JSX.Element> = {
    [StageTypes.StartStage]: (
      <StartScreen onNextStartStage={onNextStartStage} language={language} />
    ),
    [StageTypes.ValidationQuestionnaireStage]: (
      <ClassificationQuestionnaire
        projectId={projectId}
        key={questionnaireState.questionPosition}
        questionaireQuestion={
          language === 'EN'
            ? englishValidationQuestionnaireQuestions[
                questionnaireState.questionPosition
              ]
            : germanValidationQuestionnaireQuestions[
                questionnaireState.questionPosition
              ]
        }
        handleNextStep={handleNextStep}
        handleBackStep={handleBackStep}
        answers={questionnaireState.answers}
        questionPosition={questionnaireState.questionPosition}
        setRiskClass={setRiskClass}
        nextStepRiskClass={nextStepRiskClass}
        stage={StageTypes.ValidationQuestionnaireStage}
        onNextValidationStage={onNextValidationStage}
        language={language}
      />
    ),
    [StageTypes.RiskClassQuestionnaireStage]: (
      <ClassificationQuestionnaire
        projectId={projectId}
        key={questionnaireState.questionPosition}
        questionaireQuestion={
          language === 'EN'
            ? englishRiskClassificationQuestionnaireQuestions[
                questionnaireState.questionPosition
              ]
            : germanRiskClassificationQuestionnaireQuestions[
                questionnaireState.questionPosition
              ]
        }
        handleNextStep={handleNextStep}
        handleBackStep={handleBackStep}
        answers={questionnaireState.answers}
        questionPosition={questionnaireState.questionPosition}
        setRiskClass={setRiskClass}
        nextStepRiskClass={nextStepRiskClass}
        stage={StageTypes.RiskClassQuestionnaireStage}
        language={language}
      />
    ),
  };

  return (
    <div className="h-[96%] gap-4 w-full flex flex-col justify-center items-center">
      {stages[stage]}
      <div className="w-full flex justify-end">
        <Button onClick={toggleLanguage} variant="outline" className="mb-2">
          {language === 'EN' ? 'German' : 'English'}
        </Button>
      </div>
    </div>
  );
}
