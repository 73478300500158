import { useAuth } from 'react-oidc-context';
import { useRecoilCallback, RecoilState } from 'recoil';
import { LogOut } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

type LogoutButtonProps = {
  collapsed?: boolean;
  className?: string;
  /**
   * If true, styles the button for use in the sidebar
   */
  sidebar?: boolean;
  /**
   * Custom logout handler to extend functionality
   */
  onLogout?: () => void;
};

/**
 * Base LogoutButton component that doesn't depend on Recoil
 */
export function LogoutButton({
  collapsed = false,
  sidebar = false,
  className,
  onLogout,
}: LogoutButtonProps) {
  const { removeUser, signoutRedirect, clearStaleState } = useAuth();

  const handleLogout = () => {
    if (onLogout) {
      onLogout();
    }

    removeUser();
    signoutRedirect();
    clearStaleState();
    localStorage.clear();
    sessionStorage.clear();
  };

  if (sidebar) {
    return (
      <div className={cn('flex items-center', className)}>
        <button
          onClick={handleLogout}
          className="flex items-center gap-2 w-full border-0 bg-transparent hover:bg-transparent text-white cursor-pointer p-0 pl-1"
        >
          <LogOut className="h-5 w-5 min-w-5" />
          {!collapsed && (
            <span className="whitespace-nowrap text-sm font-medium">
              Sign Out
            </span>
          )}
        </button>
      </div>
    );
  }

  return (
    <Button
      variant="destructive"
      onClick={handleLogout}
      className={cn('', className)}
    >
      <LogOut className="mr-2 h-4 w-4" />
      Sign Out
    </Button>
  );
}

/**
 * Enhanced LogoutButton that also clears Recoil state
 * Must be used within a RecoilRoot
 */
export function RecoilLogoutButton(props: Omit<LogoutButtonProps, 'onLogout'>) {
  const resetAllRecoilState = useRecoilCallback(({ reset, snapshot }) => () => {
    try {
      Array.from(snapshot.getNodes_UNSTABLE()).forEach(node => {
        const isResettable = (node: unknown): node is RecoilState<unknown> => {
          return typeof node === 'object' && node !== null && '__cTag' in node;
        };

        if (isResettable(node)) {
          reset(node);
        }
      });
    } catch (error) {
      console.warn('Failed to reset Recoil state:', error);
    }
  });

  const handleRecoilLogout = () => {
    resetAllRecoilState();
  };

  return <LogoutButton {...props} onLogout={handleRecoilLogout} />;
}
