import { gql, useSuspenseQuery } from '@apollo/client';
import { withAuthenticationRequired } from 'react-oidc-context';
import { atom, useSetRecoilState } from 'recoil';
import { GetEnabledFeaturesQuery } from './__generated__/gql/graphql';
import { useEffect } from 'react';

const GET_ENABLED_FEATURES = gql`
  query GetEnabledFeatures {
    organization {
      enabledFeatures {
        showFrameworkManagement
        sidebarShowCompliance
        sidebarShowReports
        sidebarShowQuestionnaire
        sidebarShowRolesAndPermissions
        sidebarShowDevelop
        sidebarShowRiskManagement
        sidebarShowLiteracy
        checkPermission(
          targetQueryMutation: "literacyCondition"
          action: "query"
          condName: "not_literacy_trainee"
        ) {
          hasPermission
        }
      }
    }
  }
`;

export type EnabledFeatures = {
  showFrameworkManagement: boolean;
  sidebarShowCompliance: boolean;
  sidebarShowReports: boolean;
  sidebarShowQuestionnaire: boolean;
  sidebarShowRolesAndPermissions: boolean;
  sidebarShowDevelop: boolean;
  sidebarShowRiskManagement: boolean;
  sidebarShowLiteracy: boolean;
};

const defaultFeatures: EnabledFeatures = {
  showFrameworkManagement: false,
  sidebarShowCompliance: false,
  sidebarShowReports: false,
  sidebarShowQuestionnaire: false,
  sidebarShowRolesAndPermissions: false,
  sidebarShowDevelop: false,
  sidebarShowRiskManagement: false,
  sidebarShowLiteracy: false,
};

export const enabledFeaturesAtom = atom<EnabledFeatures>({
  key: 'enabledFeaturesAtom',
  default: defaultFeatures,
});

function EnabledFeaturesLoader() {
  const { data } =
    useSuspenseQuery<GetEnabledFeaturesQuery>(GET_ENABLED_FEATURES);
  const setEnabledFeatures = useSetRecoilState(enabledFeaturesAtom);
  const isNotTrainee =
    data?.organization?.enabledFeatures?.checkPermission.hasPermission;

  useEffect(() => {
    const mergedFeatures: EnabledFeatures = {
      ...defaultFeatures,
      ...(Object.fromEntries(
        Object.entries(data?.organization?.enabledFeatures ?? {})
          .filter(([key]) => key !== '__typename')
          .map(([key, value]) => [key, value ?? false])
      ) as EnabledFeatures),
    };
    if (isNotTrainee) {
      setEnabledFeatures(mergedFeatures);
    } else {
      const traineeFeatures: EnabledFeatures = {
        showFrameworkManagement: false,
        sidebarShowCompliance: false,
        sidebarShowReports: false,
        sidebarShowQuestionnaire: false,
        sidebarShowRolesAndPermissions: false,
        sidebarShowDevelop: false,
        sidebarShowRiskManagement: false,
        sidebarShowLiteracy: true,
      };
      setEnabledFeatures(traineeFeatures);
    }
  }, [data, isNotTrainee, setEnabledFeatures]);

  return null;
}

export default withAuthenticationRequired(EnabledFeaturesLoader, {
  OnRedirecting: () => <div>Redirecting to the login page...</div>,
});
