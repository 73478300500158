import React, { useState, useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { Search } from 'lucide-react';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

import UsersTable, { GET_PAGINATED_USERS } from './UsersTable';
import { Spinner } from '@/components/ui/spinner';
import { useDebounce } from '@/hooks/useDebounce';
import BulkInviteUsers from './BulkInviteUsers';
import { AccessRole } from '@/__generated__/gql/graphql';

const INVITE_NEW_USER = gql`
  mutation InviteNewUser(
    $email: String!
    $firstName: String!
    $lastName: String!
    $accessRole: AccessRole
  ) {
    inviteNewUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      accessRole: $accessRole
    ) {
      user {
        email
        name
      }
    }
  }
`;

const UserManagement: React.FC = () => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [selectedRole, setSelectedRole] = useState<AccessRole>(AccessRole.User);
  const [open, setOpen] = useState(false);
  const [bulkOpen, setBulkOpen] = useState(false);
  const [hasInvitePermission, setHasInvitePermission] = useState(false);

  const [searchInput, setSearchInput] = useState('');
  const [roleFilter, setRoleFilter] = useState<AccessRole | null>(null);
  const [registrationFilter, setRegistrationFilter] = useState<boolean | null>(
    null
  );

  const debouncedSearchTerm = useDebounce(searchInput, 300);

  const refetchVariables = {
    first: 10,
    after: null,
    last: null,
    before: null,
    includeAuditors: true,
    search: debouncedSearchTerm || null,
    role: roleFilter,
    registeredOnly: registrationFilter,
  };

  const [inviteNewUser, { loading: mutationLoading }] = useMutation(
    INVITE_NEW_USER,
    {
      refetchQueries: [
        {
          query: GET_PAGINATED_USERS,
          variables: refetchVariables,
        },
      ],
      onCompleted: data => {
        toast.success(
          `User ${data.inviteNewUser.user.name} invited successfully!`
        );
        setEmail('');
        setFirstName('');
        setLastName('');
        setSelectedRole(AccessRole.User);
        setOpen(false);
      },
      onError: error => {
        toast.error(`Error inviting user: ${error.message}`);
      },
    }
  );

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!email || !firstName || !lastName) {
      toast.error('All fields are required');
      return;
    }
    inviteNewUser({
      variables: {
        email,
        firstName,
        lastName,
        accessRole: selectedRole,
      },
    });
  };

  const handleRoleFilterChange = (value: string) => {
    if (value === 'all') {
      setRoleFilter(null);
    } else {
      setRoleFilter(value as AccessRole);
    }
  };

  const handleRegistrationFilterChange = (value: string) => {
    if (value === 'all') {
      setRegistrationFilter(null);
    } else if (value === 'registered') {
      setRegistrationFilter(true);
    } else if (value === 'unregistered') {
      setRegistrationFilter(false);
    }
  };

  const handlePermissionCheck = useCallback((hasPermission: boolean) => {
    setHasInvitePermission(hasPermission);
  }, []);

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle className="text-3xl">Users</CardTitle>
      </CardHeader>
      <CardContent className="p-6 pt-0">
        {/* Search and filter bar */}
        <div className="flex flex-col sm:flex-row w-full items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-2 mb-6">
          <div className="relative flex-1 w-full">
            <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              type="search"
              placeholder="Search users by name or email..."
              className="pl-8 pr-8"
              value={searchInput}
              onChange={e => setSearchInput(e.target.value)}
            />
          </div>

          <div className="flex space-x-2 w-full sm:w-auto">
            <Select defaultValue="all" onValueChange={handleRoleFilterChange}>
              <SelectTrigger className="w-[150px]">
                <SelectValue placeholder="Filter by role" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Roles</SelectItem>
                {Object.values(AccessRole).map(role => (
                  <SelectItem key={role} value={role}>
                    {role}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            <Select
              defaultValue="all"
              onValueChange={handleRegistrationFilterChange}
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Registration status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Users</SelectItem>
                <SelectItem value="registered">Registered Users</SelectItem>
                <SelectItem value="unregistered">Unregistered Users</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        <UsersTable
          search={debouncedSearchTerm}
          roleFilter={roleFilter}
          registrationFilter={registrationFilter}
          onPermissionCheck={handlePermissionCheck}
        />

        <div className="flex gap-4 mt-8">
          <Button
            variant="outline"
            onClick={() => setOpen(true)}
            disabled={!hasInvitePermission}
          >
            Add New User
          </Button>

          <Button
            variant="outline"
            onClick={() => setBulkOpen(true)}
            disabled={!hasInvitePermission}
          >
            Bulk Invite Users
          </Button>
        </div>

        <Dialog open={open} onOpenChange={setOpen}>
          <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle>Invite New User</DialogTitle>
              <DialogDescription>
                Fill in the details to invite a new user to the platform.
              </DialogDescription>
            </DialogHeader>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value.toLowerCase())}
                  required
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="firstName">First Name</Label>
                <Input
                  id="firstName"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  required
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="lastName">Last Name</Label>
                <Input
                  id="lastName"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                  required
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="role">Role</Label>
                <Select
                  value={selectedRole}
                  onValueChange={value => setSelectedRole(value as AccessRole)}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select a role" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.values(AccessRole).map(role => (
                      <SelectItem key={role} value={role}>
                        {role}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <DialogFooter>
                {mutationLoading ? (
                  <div className="flex justify-center p-2">
                    <Spinner />
                  </div>
                ) : (
                  <Button type="submit">Invite</Button>
                )}
              </DialogFooter>
            </form>
          </DialogContent>
        </Dialog>

        <BulkInviteUsers open={bulkOpen} onOpenChange={setBulkOpen} />
      </CardContent>
    </Card>
  );
};

export default UserManagement;
