import { useSuspenseQuery, gql, useMutation } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Grid2X2, Columns2, Search } from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import ProjectDetailCard from './ProjectDetailCard';
import ProjectCreationModal from './project_creation_modal/ProjectCreationModal.react';
import { useProject } from '../contexts/project';
import {
  AllProjectsForProjectViewQuery,
  DataModality,
  Department,
  GetDataModalitiesQuery,
  GetDepartmentsQuery,
  GetItAssetsQuery,
  ItAsset,
  Project,
  RiskClass,
  Stage,
  UsersBasicInfoQuery,
} from '../__generated__/gql/graphql';
import ProjectDetailDrawerSuspenseWrapper from './ProjectDetailDrawerSuspenseWrapper';

import ProjectFilter, {
  filterProjects,
  ProjectBaseFilter,
  ProjectFilterProps,
} from './ProjectFilter';
import CardGrid from '../components/CardGrid';
import { ProjectImportModal } from './project_creation_modal/ProjectImportModal';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { KanbanBoard } from '@/components/KanbanBoard';
import { Column } from '@/components/BoardColumn';
import { Task } from '@/components/TaskCard';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { UniqueIdentifier } from '@dnd-kit/core';

// GraphQL Queries
export const GET_PROJECTS = gql(`
  query AllProjectsForProjectView {
    allProjects {
      id
      title
      description
      departments {
        id
        name
      }
      isPurchased
      riskClass {
        riskClassCategory
        riskClassRole
      }
      stage
      responsible {
        id
        name
        email
        initials
      }
    }
  }
`);

export const GET_USERS = gql(`
  query UsersBasicInfo {
    allUsers {
      id
      name
      email
    }
  }
`);

export const GET_IT_ASSETS = gql`
  query GetItAssets {
    allItAssets {
      id
      name
      created
      lastModified
    }
  }
`;

export const GET_DEPARTMENTS = gql(`
  query GetDepartments {
    allDepartments {
      id
      name
    }
  }
`);

export const GET_DATA_MODALITIES = gql(`
  query GetDataModalities {
    allDataModalities {
      id
      name
    }
  }
`);

const UPDATE_PROJECT_STAGE = gql`
  mutation UpdateProjectStage($projectId: String!, $stage: String!) {
    updateProject(projectId: $projectId, stage: $stage) {
      success
      project {
        id
        stage
      }
    }
  }
`;

export enum ViewMode {
  Grid = 'grid',
  Kanban = 'kanban',
}

type ProjectStage = Stage | 'NO_STAGE';

const formatRiskClass = (riskClass: RiskClass | null | undefined): string => {
  if (!riskClass || !riskClass.riskClassCategory) return 'Not Defined';
  return riskClass.riskClassCategory;
};

const getProjectStages = (projects: Project[]): Column[] => {
  const stageOrder: ProjectStage[] = [
    'NO_STAGE' as ProjectStage,
    Stage.Plan,
    Stage.Develop,
    Stage.Deploy,
    Stage.Operate,
    Stage.Retire,
  ];

  const existingStages = new Set(
    projects.map(p => p.stage || ('NO_STAGE' as ProjectStage))
  );

  return stageOrder
    .filter(stage => existingStages.has(stage))
    .map(stage => ({
      id: stage,
      title: stage === 'NO_STAGE' ? 'No Stage' : stage,
    }));
};

const projectsToTasks = (projects: Project[]): Task[] => {
  return projects.map(project => ({
    id: project.id,
    columnId: (project.stage || 'NO_STAGE') as ProjectStage,
    content: project.title,
    project: {
      id: project.id,
      title: project.title,
      description: project.description ?? undefined,
      departments: project.departments ?? undefined,
      responsible: project.responsible
        ? {
            name: project.responsible.name ?? '',
            email: project.responsible.email ?? '',
          }
        : undefined,
      riskClass: project.riskClass
        ? {
            riskClassCategory: project.riskClass.riskClassCategory ?? '',
            riskClassRole: project.riskClass.riskClassRole ?? undefined,
          }
        : undefined,
      isPurchased: project.isPurchased ?? undefined,
    },
  }));
};

export default function ProjectView() {
  const { data: projectsData } =
    useSuspenseQuery<AllProjectsForProjectViewQuery>(GET_PROJECTS);
  const { data: userData } = useSuspenseQuery<UsersBasicInfoQuery>(GET_USERS);
  const { data: departmentsData } =
    useSuspenseQuery<GetDepartmentsQuery>(GET_DEPARTMENTS);
  const { data: itAssetsData } =
    useSuspenseQuery<GetItAssetsQuery>(GET_IT_ASSETS);
  const { data: dataModalitiesData } =
    useSuspenseQuery<GetDataModalitiesQuery>(GET_DATA_MODALITIES);

  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Grid);
  const [searchTerm, setSearchTerm] = useState('');
  const [projectFilters, setProjectFilters] =
    useState<ProjectFilterProps>(ProjectBaseFilter);
  const [showCreationModal, setShowCreationModal] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [selectedProject, setSelectedProject] = useState<Project | undefined>();
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [activeColumn, setActiveColumn] = useState<Column | null>(null);
  const [activeTask, setActiveTask] = useState<Task | null>(null);

  const filteredProjects = useMemo(
    () =>
      (projectsData?.allProjects?.filter(project => {
        if (!project) return false;

        const matchesSearch = project.title
          .toLowerCase()
          .includes(searchTerm.toLowerCase());

        const matchesFilters = filterProjects({
          responsible: project.responsible?.id,
          stage: project.stage,
          departments: project.departments as Department[],
          riskClass: project.riskClass?.riskClassCategory,
          isPurchased: project.isPurchased,
          filters: projectFilters,
        });

        return matchesSearch && matchesFilters;
      }) ?? []) as Project[],
    [projectsData?.allProjects, searchTerm, projectFilters]
  );

  const [tasks, setTasks] = useState<Task[]>([]);
  const [columns, setColumns] = useState<Column[]>([]);
  const [updateProjectStage] = useMutation(UPDATE_PROJECT_STAGE);
  const [draggingTaskOriginalColumn, setDraggingTaskOriginalColumn] = useState<
    string | null
  >(null);
  const handleActiveTaskChange = useCallback((task: Task | null) => {
    setActiveTask(task);
    if (task) {
      setDraggingTaskOriginalColumn(task.columnId);
    } else {
      setDraggingTaskOriginalColumn(null);
    }
  }, []);
  const [stageUpdateConfirm, setStageUpdateConfirm] = useState<{
    show: boolean;
    taskId: string;
    newStage: Stage;
    originalColumnId: ProjectStage;
  } | null>(null);

  useMemo(() => {
    setTasks(projectsToTasks(filteredProjects));
    setColumns(getProjectStages(filteredProjects));
  }, [filteredProjects]);

  const handleTaskChange = useCallback(
    (
      newTasks: Task[],
      isFinalDrop: boolean,
      activeId: UniqueIdentifier | undefined
    ) => {
      setTasks(newTasks);
      if (isFinalDrop && activeId && draggingTaskOriginalColumn) {
        const movedTask = newTasks.find(task => task.id === activeId);
        if (movedTask && movedTask.columnId !== draggingTaskOriginalColumn) {
          setStageUpdateConfirm({
            show: true,
            taskId: String(movedTask.id),
            newStage: movedTask.columnId as Stage,
            originalColumnId: draggingTaskOriginalColumn as ProjectStage,
          });
        }
      }
    },
    [draggingTaskOriginalColumn]
  );
  const handleColumnChange = useCallback((newColumns: Column[]) => {
    setColumns(newColumns);
  }, []);

  const handleTaskClick = (task: Task) => {
    if (task.project) {
      const projectData = projectsData?.allProjects?.find(
        p => p?.id === task.project?.id
      );
      if (projectData) {
        setSelectedProject(projectData as Project);
        setShowSidebar(true);
      }
    }
  };
  const handleStageUpdateConfirm = async () => {
    if (!stageUpdateConfirm) return;

    const projectId = stageUpdateConfirm.taskId;
    const stage = stageUpdateConfirm.newStage;

    try {
      const result = await updateProjectStage({
        variables: { projectId, stage },
        optimisticResponse: {
          updateProject: {
            success: true,
            project: {
              __typename: 'Project',
              id: projectId,
              stage,
            },
          },
        },
      });
      if (!result.data?.updateProject?.success) {
        throw new Error('Failed to update project stage');
      }
    } catch (error) {
      console.error('Failed to update project stage:', error);
      setTasks(prevTasks =>
        prevTasks.map(task =>
          task.id === projectId
            ? { ...task, columnId: stageUpdateConfirm.originalColumnId }
            : task
        )
      );
    }

    setStageUpdateConfirm(null);
  };

  const handleStageUpdateCancel = () => {
    if (stageUpdateConfirm) {
      setTasks(prevTasks =>
        prevTasks.map(task =>
          task.id === stageUpdateConfirm.taskId
            ? { ...task, columnId: stageUpdateConfirm.originalColumnId }
            : task
        )
      );
      setStageUpdateConfirm(null);
    }
  };
  const [params] = useSearchParams();
  const { project: currentlyActiveProject, setProject } = useProject();
  const showBetaFeatures = useFeatureFlagEnabled('beta-tester');

  const filteredItAssets =
    itAssetsData?.allItAssets?.filter(
      (asset): asset is ItAsset => asset !== null
    ) ?? [];

  useMemo(() => {
    const projectFromParam =
      projectsData?.allProjects?.find(
        p => p?.id === params.get('projectDetailsFor')
      ) || undefined;

    if (projectFromParam) {
      setSelectedProject(projectFromParam as Project);
      setShowSidebar(true);
    }
  }, [params, projectsData?.allProjects]);

  return (
    <div className="flex w-full gap-4 justify-center flex-col items-center">
      {/* Header */}
      <div className="flex w-full justify-between items-center mb-6">
        <h1>AI Registry</h1>
        <Tabs
          defaultValue={viewMode}
          onValueChange={(value: string) => setViewMode(value as ViewMode)}
        >
          <TabsList>
            <TabsTrigger
              value={ViewMode.Grid}
              className="data-[state=active]:bg-white/90 data-[state=active]:text-black"
            >
              <Grid2X2 className="w-5 h-5 mr-2" />
              Grid
            </TabsTrigger>
            <TabsTrigger
              value={ViewMode.Kanban}
              className="data-[state=active]:bg-white/90  data-[state=active]:text-black"
            >
              <Columns2 className="w-5 h-5 mr-2" />
              Board
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>

      {/* Search and Filters */}
      <div className="flex justify-center mb-5 gap-3 items-center">
        <div className="relative w-[700px]">
          <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            id="search"
            className="pl-8"
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search for a project ..."
          />
        </div>

        <ProjectFilter
          projectFilters={projectFilters}
          setProjectFilters={setProjectFilters}
          userData={userData}
          departmentsData={departmentsData.allDepartments as Department[]}
        />
        {showBetaFeatures && (
          <Button onClick={() => setImportModalOpen(true)} variant="outline">
            Import Data
          </Button>
        )}
      </div>

      {/* Main Content */}
      {viewMode === 'grid' ? (
        <CardGrid
          items={filteredProjects}
          title={
            filteredProjects.length === 0
              ? 'No Project'
              : `${filteredProjects.length} ${
                  filteredProjects.length === 1 ? 'Project' : 'Projects'
                }`
          }
          renderCard={(projectData: Project) => (
            <ProjectDetailCard
              key={projectData.id}
              id={projectData.id}
              departments={projectData.departments ?? []}
              stage={projectData.stage}
              user={projectData.responsible}
              riskClass={formatRiskClass(projectData.riskClass)}
              onClick={() => {
                setSelectedProject(projectData);
                setShowSidebar(!showSidebar);
              }}
              isPurchased={projectData.isPurchased ?? false}
              title={projectData.title}
              description={projectData.description ?? ''}
              isSelected={currentlyActiveProject?.id === projectData.id}
            />
          )}
          onAddNew={() => setShowCreationModal(true)}
        />
      ) : (
        <div className="w-[800px] xl:w-[1160px] 2xl:w-[1400px]">
          <h4>
            {filteredProjects.length === 0
              ? 'No Project'
              : `${filteredProjects.length} ${
                  filteredProjects.length === 1 ? 'Project' : 'Projects'
                }`}
          </h4>
          <KanbanBoard
            columns={columns}
            tasks={tasks}
            activeColumn={activeColumn}
            activeTask={activeTask}
            onColumnChange={handleColumnChange}
            onTaskChange={handleTaskChange}
            onActiveColumnChange={setActiveColumn}
            onActiveTaskChange={handleActiveTaskChange}
            onTaskClick={handleTaskClick}
          />
        </div>
      )}

      {/* Modals and Drawers */}
      {showCreationModal && (
        <ProjectCreationModal
          isOpen={showCreationModal}
          onClose={() => setShowCreationModal(false)}
          userData={userData}
          departmentsData={departmentsData.allDepartments as Department[]}
          allItAssets={filteredItAssets}
          dataModalitiesData={
            dataModalitiesData.allDataModalities as DataModality[]
          }
        />
      )}

      <ProjectImportModal
        isOpen={importModalOpen}
        onOpenChange={setImportModalOpen}
      />

      {selectedProject && (
        <ProjectDetailDrawerSuspenseWrapper
          key={selectedProject.id}
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
          selectedProject={selectedProject}
          setProject={setProject}
          allItAssets={filteredItAssets}
          allDataModalities={
            dataModalitiesData.allDataModalities as DataModality[]
          }
          allDepartments={departmentsData.allDepartments as Department[]}
        />
      )}
      {stageUpdateConfirm && (
        <AlertDialog
          open={stageUpdateConfirm.show}
          onOpenChange={open => {
            if (!open) handleStageUpdateCancel();
          }}
        >
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Confirm Stage Change</AlertDialogTitle>
              <AlertDialogDescription>
                Are you sure you want to change the project stage to{' '}
                {stageUpdateConfirm.newStage}? This action will update the
                project's status in the system.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel onClick={handleStageUpdateCancel}>
                Cancel
              </AlertDialogCancel>
              <AlertDialogAction onClick={handleStageUpdateConfirm}>
                Confirm
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </div>
  );
}
