import React, { useState, useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { Upload, X, AlertCircle, CheckCircle2, FileText } from 'lucide-react';
import Papa from 'papaparse';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Badge } from '@/components/ui/badge';
import { Spinner } from '@/components/ui/spinner';

import { GET_PAGINATED_USERS } from './UsersTable';
import { AccessRole } from '@/__generated__/gql/graphql';

const BULK_INVITE_USERS = gql`
  mutation BulkInviteUsers($usersData: [[String!]!]!) {
    bulkInviteUsers(usersData: $usersData) {
      users {
        email
        name
      }
      count
    }
  }
`;

const VALID_ACCESS_ROLE_STRINGS = Object.values(AccessRole).map(role =>
  role.toString()
);

interface CsvValidationError {
  row: number;
  message: string;
}

interface BulkInviteUsersProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

const BulkInviteUsers: React.FC<BulkInviteUsersProps> = ({
  open,
  onOpenChange,
}) => {
  const [csvData, setCsvData] = useState<string[][]>([]);
  const [csvErrors, setCsvErrors] = useState<CsvValidationError[]>([]);
  const [csvFileName, setCsvFileName] = useState<string>('');
  const [bulkSuccess, setBulkSuccess] = useState<{
    users: { email: string; name: string }[];
    count: number;
  } | null>(null);
  const [bulkInviteUsers, { loading: bulkLoading }] = useMutation(
    BULK_INVITE_USERS,
    {
      refetchQueries: [
        {
          query: GET_PAGINATED_USERS,
          variables: {
            first: 10,
            after: null,
            last: null,
            before: null,
            includeAuditors: true,
            search: null,
            role: null,
          },
        },
      ],
      onCompleted: data => {
        setBulkSuccess({
          users: data.bulkInviteUsers.users,
          count: data.bulkInviteUsers.count,
        });

        toast.success(
          `Successfully invited ${data.bulkInviteUsers.count} users!`
        );
      },
      onError: error => {
        // Extract validation errors from GraphQL error message
        const errorMessage = error.message;
        if (errorMessage.includes('CSV validation failed:')) {
          const validationErrors = errorMessage
            .split('CSV validation failed:')[1]
            .trim()
            .split('\n')
            .map(err => {
              const match = err.match(/Row (\d+): (.*)/);
              if (match) {
                return {
                  row: parseInt(match[1], 10),
                  message: match[2],
                };
              }
              return { row: 0, message: err };
            });

          setCsvErrors(validationErrors);
          toast.error(
            `CSV validation failed with ${validationErrors.length} errors`
          );
        } else {
          toast.error(`Error processing bulk invitation: ${errorMessage}`);
        }
      },
    }
  );

  const validateCsvData = (data: string[][]): CsvValidationError[] => {
    const errors: CsvValidationError[] = [];

    if (data.length === 0) {
      errors.push({ row: 0, message: 'CSV file is empty' });
      return errors;
    }

    const hasHeader =
      data[0][0]?.toLowerCase() === 'first name' ||
      data[0][0]?.toLowerCase() === 'firstname';

    const dataRows = hasHeader ? data.slice(1) : data;

    dataRows.forEach((row: string[], index: number) => {
      const rowNum = hasHeader ? index + 2 : index + 1;

      if (row.length < 3) {
        errors.push({
          row: rowNum,
          message:
            'Missing required fields. Each row must have first name, last name, and email.',
        });
      } else {
        if (!row[0] || !row[0].trim()) {
          errors.push({
            row: rowNum,
            message: 'First name cannot be empty',
          });
        }

        if (!row[1] || !row[1].trim()) {
          errors.push({
            row: rowNum,
            message: 'Last name cannot be empty',
          });
        }

        if (!row[2] || !row[2].includes('@')) {
          errors.push({
            row: rowNum,
            message: `Invalid email address: ${row[2] || 'empty'}`,
          });
        }

        if (
          row.length >= 4 &&
          row[3] &&
          !VALID_ACCESS_ROLE_STRINGS.includes(row[3].toUpperCase())
        ) {
          errors.push({
            row: rowNum,
            message: `Invalid access role: ${
              row[3]
            }. Must be one of: ${VALID_ACCESS_ROLE_STRINGS.join(', ')}`,
          });
        }
      }
    });

    return errors;
  };

  const handleBulkSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (csvData.length === 0) {
      toast.error('Please upload a CSV file first');
      return;
    }

    if (csvErrors.length > 0) {
      toast.error('Please fix CSV errors before submitting');
      return;
    }

    const dataToSubmit =
      csvData.length > 0 &&
      (csvData[0][0]?.toLowerCase() === 'first name' ||
        csvData[0][0]?.toLowerCase() === 'firstname')
        ? csvData.slice(1)
        : csvData;

    if (dataToSubmit.length === 0) {
      toast.error('No users to invite');
      return;
    }

    bulkInviteUsers({ variables: { usersData: dataToSubmit } });
  };

  const handleFileUpload = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) return;

      setCsvErrors([]);
      setBulkSuccess(null);
      setCsvFileName(file.name);

      Papa.parse<string[]>(file, {
        complete: results => {
          const parsedData = results.data;

          const cleanData = parsedData.filter(row =>
            row.some(cell => cell && cell.trim && cell.trim().length > 0)
          );

          setCsvData(cleanData);

          const errors = validateCsvData(cleanData);
          setCsvErrors(errors);

          if (errors.length > 0) {
            toast.error(`CSV validation failed with ${errors.length} errors`);
          } else {
            const hasHeader =
              cleanData[0][0]?.toLowerCase() === 'first name' ||
              cleanData[0][0]?.toLowerCase() === 'firstname';
            const userCount = cleanData.length - (hasHeader ? 1 : 0);
            toast.success(
              `CSV file parsed successfully with ${userCount} users`
            );
          }
        },
        error: error => {
          setCsvErrors([
            { row: 0, message: `Error parsing CSV: ${error.message}` },
          ]);
          toast.error(`Error parsing CSV: ${error.message}`);
        },
      });

      event.target.value = '';
    },
    []
  );

  const resetBulkUpload = () => {
    setCsvData([]);
    setCsvErrors([]);
    setBulkSuccess(null);
    setCsvFileName('');
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[700px]">
        <DialogHeader>
          <DialogTitle>Bulk Invite Users</DialogTitle>
          <DialogDescription>
            Upload a CSV file with user details to invite multiple users at
            once.
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-4">
          <div className="p-4 rounded-md">
            <h4 className="font-medium text-white mb-3">
              CSV Format Instructions
            </h4>
            <div className="space-y-3">
              <div>
                <p className="text-sm font-medium text-white mb-1">
                  Required Columns:
                </p>
                <div className="grid grid-cols-3 gap-2 ml-4">
                  <div className="text-sm text-white">
                    <span className="font-medium text-white">1.</span> First
                    Name
                  </div>
                  <div className="text-sm text-white">
                    <span className="font-medium text-white">2.</span> Last Name
                  </div>
                  <div className="text-sm text-white">
                    <span className="font-medium text-white">3.</span> Email
                  </div>
                </div>
              </div>

              <div>
                <p className="text-sm font-medium text-white mb-1">
                  Optional Column:
                </p>
                <div className="ml-4 text-sm text-white">
                  <span className="font-medium text-white">4.</span> Access Role
                  - Must be one of: {VALID_ACCESS_ROLE_STRINGS.join(', ')}
                </div>
              </div>

              <div className="border-t border-gray-600 pt-2 mt-2">
                <p className="text-sm text-white">
                  <span className="font-medium text-white">Note:</span> A header
                  row is recommended but not required.
                </p>
                <p className="text-sm text-white mt-1">
                  Example:{' '}
                  <code className="bg-gray-700 px-1 py-0.5 rounded text-xs border border-gray-600 text-white">
                    First Name,Last Name,Email,Access Role
                  </code>
                </p>
              </div>
            </div>
          </div>

          {csvErrors.length > 0 && (
            <Alert variant="destructive">
              <AlertCircle className="h-4 w-4" />
              <AlertTitle>CSV Validation Errors</AlertTitle>
              <AlertDescription>
                <ul className="list-disc list-inside text-sm space-y-1 mt-2">
                  {csvErrors.map((error, index) => (
                    <li key={index}>
                      {error.row > 0 ? `Row ${error.row}: ` : ''}
                      {error.message}
                    </li>
                  ))}
                </ul>
              </AlertDescription>
            </Alert>
          )}

          {bulkSuccess && (
            <div className="space-y-3">
              <Alert>
                <CheckCircle2 className="h-4 w-4 text-green-500" />
                <AlertTitle className="text-green-700">Success</AlertTitle>
                <AlertDescription className="text-green-600">
                  Successfully invited {bulkSuccess.count} users to the
                  platform.
                </AlertDescription>
              </Alert>

              <div className="max-h-[200px] overflow-y-auto border rounded-md">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Email</TableHead>
                      <TableHead>Name</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {bulkSuccess.users.map((user, index) => (
                      <TableRow key={index}>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          )}

          {!bulkSuccess && (
            <>
              {csvFileName ? (
                <div className="flex items-center justify-between p-3 border rounded-md">
                  <div className="flex items-center">
                    <FileText className="h-5 w-5 text-blue-500 mr-2" />
                    <div>
                      <p className="font-medium">{csvFileName}</p>
                      <p className="text-xs text-muted-foreground">
                        {csvData.length} rows
                        {csvErrors.length > 0 && (
                          <Badge variant="destructive" className="ml-2">
                            {csvErrors.length} errors
                          </Badge>
                        )}
                      </p>
                    </div>
                  </div>
                  <Button variant="ghost" size="sm" onClick={resetBulkUpload}>
                    <X className="h-4 w-4" />
                  </Button>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center border-2 border-dashed rounded-md p-8">
                  <Input
                    id="csvUpload"
                    type="file"
                    accept=".csv"
                    onChange={handleFileUpload}
                    className="hidden"
                  />
                  <Label
                    htmlFor="csvUpload"
                    className="flex flex-col items-center cursor-pointer"
                  >
                    <Upload className="h-8 w-8 text-muted-foreground mb-2" />
                    <span className="text-sm font-medium">
                      Click to upload CSV
                    </span>
                    <span className="text-xs text-muted-foreground mt-1">
                      or drag and drop
                    </span>
                  </Label>
                </div>
              )}

              {csvData.length > 0 && (
                <div>
                  <h4 className="font-medium text-white mb-3">
                    Preview ({csvData.length} rows):
                  </h4>
                  <div className="max-h-[200px] overflow-y-auto border border-slate-200 rounded-md shadow-sm">
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHead className="font-medium bg-slate-700 text-white">
                            First Name
                          </TableHead>
                          <TableHead className="font-medium bg-slate-700 text-white">
                            Last Name
                          </TableHead>
                          <TableHead className="font-medium bg-slate-700 text-white">
                            Email
                          </TableHead>
                          <TableHead className="font-medium bg-slate-700 text-white">
                            Access Role
                          </TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {csvData.map((row, index) => {
                          // Check if this is a header row
                          const isHeader =
                            index === 0 &&
                            (row[0]?.toLowerCase() === 'first name' ||
                              row[0]?.toLowerCase() === 'firstname');

                          // Skip the first row if it's a header
                          if (isHeader) {
                            return null;
                          }

                          const rowIndex = isHeader ? index - 1 : index;
                          const cellClassName =
                            rowIndex % 2 === 0
                              ? 'py-2 bg-slate-600 text-white'
                              : 'py-2 bg-slate-500 text-white';

                          return (
                            <TableRow key={index}>
                              <TableCell className={cellClassName}>
                                {row[0] || ''}
                              </TableCell>
                              <TableCell className={cellClassName}>
                                {row[1] || ''}
                              </TableCell>
                              <TableCell className={cellClassName}>
                                {row[2] || ''}
                              </TableCell>
                              <TableCell className={cellClassName}>
                                {row[3] ? (
                                  <span className="font-medium">
                                    {VALID_ACCESS_ROLE_STRINGS.includes(
                                      row[3].toUpperCase()
                                    ) ? (
                                      row[3].toUpperCase()
                                    ) : (
                                      <span className="text-red-300">
                                        {row[3].toUpperCase()} (invalid)
                                      </span>
                                    )}
                                  </span>
                                ) : (
                                  <span className="opacity-75">
                                    USER (default)
                                  </span>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        <DialogFooter className="flex justify-between">
          {bulkSuccess ? (
            <Button type="button" onClick={resetBulkUpload} variant="outline">
              Upload Another CSV
            </Button>
          ) : (
            <>
              {csvFileName && (
                <>
                  <Button
                    type="button"
                    onClick={resetBulkUpload}
                    variant="outline"
                    disabled={bulkLoading}
                  >
                    Clear
                  </Button>
                  <Button
                    type="button"
                    onClick={handleBulkSubmit}
                    disabled={
                      csvData.length === 0 ||
                      csvErrors.length > 0 ||
                      bulkLoading
                    }
                  >
                    {bulkLoading ? <Spinner className="mr-2" /> : null}
                    Invite Users
                  </Button>
                </>
              )}
            </>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default BulkInviteUsers;
