import { FC } from 'react';
import { Checkbox } from '@/components/ui/checkbox';
import { Check } from 'lucide-react';
import { Button } from '@/components/ui/button';

interface QuestionnaireAnswerCheckboxProps {
  option: { name: string; value: number };
  handleCheckboxValue: (value: number) => void;
  isChecked: boolean;
}

const QuestionnaireAnswerCheckbox: FC<QuestionnaireAnswerCheckboxProps> = ({
  option,
  handleCheckboxValue,
  isChecked,
}) => {
  const handleChange = () => {
    handleCheckboxValue(option.value);
  };

  return (
    <Button
      variant="outline"
      className={`
        w-full h-[120px] px-5 py-5 flex items-center justify-start gap-3
        bg-dark-blue-400 border-gray-400 hover:bg-dark-blue-500 cursor-pointer
        ${isChecked ? 'border-dark-blue-500 bg-dark-blue-500' : ''}
      `}
      onClick={handleChange}
    >
      <div
        className={`
          relative w-6 h-6 rounded-full border-2 border-white
          flex items-center justify-center
          ${isChecked ? 'bg-white' : 'bg-transparent'}
        `}
      >
        <Checkbox
          checked={isChecked}
          onCheckedChange={handleChange}
          className="opacity-0 absolute w-full h-full cursor-pointer m-0 p-0"
        />
        {isChecked && (
          <Check className="text-dark-blue-500 w-[18px] h-[18px]" />
        )}
      </div>
      <span className="w-[85%] flex items-center justify-center text-white text-sm whitespace-pre-line break-words">
        {option.name}
      </span>
    </Button>
  );
};

export default QuestionnaireAnswerCheckbox;
